
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import MrAddModal from "@/components/room/MrAddModal.vue";
import MrDeleteRegistrantModal from "@/components/room/MrDeleteRegistrantModal.vue";
import { Registrant } from "@/entities/registrant";
import { Student, StudentSecret } from "@/entities/student";
import { Room } from "@/entities/room";
import { School } from "@/entities/school";
import { Classroom } from "@/entities/classroom";
import { Group } from "@/entities/group";
import { Target } from "@/entities/target";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import {
  convertToRegistrantTableData,
  registrantsOfAdminTableDefinition,
  registrantsOfTutorTableDefinition,
  TableDefinition
} from "@/utils/table";

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MNavBar,
    MHeaderPanel,
    MrAddModal,
    MrDeleteRegistrantModal
  }
})
export default class RoomStudentList extends Vue {
  tabs: TabItem[] = [
    {
      text: "開室中",
      link: "/room"
    },
    {
      text: "今後の予定",
      link: "/room/future"
    },
    {
      text: "入室中生徒",
      link: "/room/student"
    }
  ];
  currentTabIndex = 2;
  searchKeyword = "";
  selectedRegistrantId = "";
  isDisplayedAddRoomModal = false;
  isDisplayedDeleteRegistrantModal = false;

  get definitions(): TableDefinition[] {
    return this.isAdmin
      ? registrantsOfAdminTableDefinition
      : registrantsOfTutorTableDefinition;
  }

  @State("rooms", "room") rooms!: Room[];
  @Getter("learningRegistrants", "room") registrants!: Registrant[];

  @State("school") school!: School | null;
  @State("schools") schools!: School[];
  @State("classrooms") classrooms!: Classroom[];
  @State("students") students!: Student[];
  @State("studentSecrets") studentSecrets!: StudentSecret[];
  @State("groups") groups!: Group[];
  @State("targets") targets!: Target[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("allowCreateRoom") allowCreateRoom!: boolean;

  get selectedStudent(): Student | null {
    const matchRegistrants = this.registrants.filter(
      registrant => registrant.ref.id === this.selectedRegistrantId
    );
    if (matchRegistrants.length === 0) {
      return null;
    }

    const matchStudents = this.students.filter(
      student => student.ref.id === matchRegistrants[0].data.student.id
    );
    if (matchStudents.length === 0) {
      return null;
    }

    return matchStudents[0];
  }

  get datas(): { [key: string]: string | number | boolean }[] {
    const registrants = this.registrants.filter(item =>
      this.students
        .map(student => student.ref.id)
        .includes(item.data.student.id)
    );

    return registrants.map(item =>
      convertToRegistrantTableData(
        item,
        this.students,
        this.studentSecrets,
        this.rooms,
        this.schools,
        this.classrooms
      )
    );
  }

  get filteredDatas(): { [key: string]: string | number | boolean }[] {
    if (this.searchKeyword.length === 0) {
      return this.datas;
    }

    return this.datas.filter(
      data =>
        Object.keys(data).includes("name") &&
        data.name
          .toString()
          .toLowerCase()
          .includes(this.searchKeyword.toLowerCase())
    );
  }

  openDeleteRegistrantModal(id: string) {
    this.selectedRegistrantId = id;
    this.isDisplayedDeleteRegistrantModal = true;
  }

  created() {
    store.dispatch("room/setup");
  }
}
