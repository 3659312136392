
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import dayjs from "dayjs";
import QrcodeVue from "qrcode.vue";
import { Options, Vue } from "vue-class-component";
import { Student } from "@/entities/student";
import { deleteRegistrantOfStudent } from "@/api/registrant";
import { saveErrorLog } from "@/api/error";

function convertToUnixtime(date: string, time: string): number {
  return dayjs(`${date} ${time}`).locale("ja").unix();
}

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField,
    QrcodeVue
  },
  emits: ["close"],
  props: {
    student: Object
  }
})
export default class MrDeleteRegistrantModal extends Vue {
  student!: Student;
  date = "";
  time = "";

  get validDate(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.date);
  }

  get validTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.time);
  }

  get validData(): boolean {
    return this.validDate && this.validTime;
  }

  async deleteRegistrant() {
    if (!this.validData) {
      return;
    }

    store.commit("START_LOADING", "削除中...");

    const end = convertToUnixtime(this.date, this.time);

    try {
      await deleteRegistrantOfStudent(this.student, end);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete registrant"
      );
      return;
    }
    store.commit("END_LOADING");

    alert("対象生徒の入室記録を削除しました");
    this.$emit("close");
  }

  created() {
    const dayData = dayjs().locale("ja");
    this.date = dayData.format("YYYY-MM-DD");
    this.time = dayData.format("HH:mm");
  }

  close() {
    this.$emit("close");
  }
}
